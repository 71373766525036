import { useEffect, useState } from "react";
import ApiWrapper from "../../utils/api-wrapper";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { observer, inject } from 'mobx-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faAngleDoubleRight, faArrowDown, faArrowUp, faChevronLeft, faChevronRight, faSpinner, faPencilAlt, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { faSync } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../components/modal';
import Checkbox from "../../components/checkbox";
import GlNatural from "../../components/gl-natural";

const AdminGlNaturals = ({ data }) => {
    const source = axios.CancelToken.source();
    const [glNaturals, setGlNaturals] = useState([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showImportModal, setShowImportModal] = useState(false);
    const [saving, setSaving] = useState(false);
    const [sorting, setSorting] = useState([{"id": "GLNatural", "desc": false}]);
    const [filters, setFilters] = useState([]);
    const [searchStr, setSearchStr] = useState("");
    const [globalfilter, setGlobalfilter] = useState("");
    const [selected, setSelected] = useState(null);
    const [importFile, setImportFile] = useState(null);
    const [savingError, setSavingError] = useState(null);
    const [glNaturalsDeleteSelection, setGlNaturalsDeleteSelection] = useState([]);
    const [glNaturalsDeleteConfirm, setGlNaturalsDeleteConfirm] = useState(false);
    const toggleAllGlNaturalsSelected = () => {
        glNaturalsDeleteSelection.length === glNaturals.length ? setGlNaturalsDeleteSelection([]) : setGlNaturalsDeleteSelection([...glNaturals.flatMap(x => x._id.$oid)]);
    }
    const isGlNaturalSelected = (gl) => {
        return glNaturalsDeleteSelection.find(x => x === gl._id.$oid) !== undefined;
    }

    const processImport = () => {
        if (importFile != null) {
            const dataForm = new FormData();
            dataForm.append("csv", importFile);

            ApiWrapper.fetch(this, "/gl_naturals/import", "POST", dataForm,
                response => {
                    setImportFile(null);
                    setShowImportModal(false);
                },
                (error) => {
                    setSavingError(data.t.read("error"));
                     setImportFile(null)
                    }, true, source.token
                )
        }
    }
    
    const availableColumns = [
        //{ "name": "id", "evaluate": inv => inv._id ? inv._id.$oid : "", "displayName": "identifiant" },
        { "name": "GLNatural", "evaluate": inv => inv.GLNatural, "displayName": "gl_natural" },
        { "name": "budget", "evaluate": inv => inv.budget ? `$ ${inv.budget.toLocaleString(`${data.t.lang}-${data.t.lang.toUpperCase()}`, { maximumFractionDigits: 2 })}` : "", "displayName": "budget" },
        { "name": "category", "evaluate": inv => inv.category ? data.t.read(`gl_category.${inv.category.value}`) : "", "displayName": "category" },
        { "name": "label", "evaluate": inv => inv.label ? inv.label : "", "displayName": "label" },
        { "name": "active", "evaluate": inv => inv.active && inv.active === true ? data.t.read("rule.active") : data.t.read("rule.inactive"), "displayName": "client.users.active" },
        //{ "name": "json.DueDate", "evaluate": inv => inv.json?.DueDate ? inv.json.DueDate : "", "displayName": "due_date" },
        //{ "name": "json.ListSupplier[0].Number", "evaluate": inv => inv.json?.ListSupplier && inv.json.ListSupplier.length > 0 ? inv.json.ListSupplier[0].Number : "", "displayName": "supplier_number" },
        //{ "name": "json.ListSupplier[0].Name", "evaluate": inv => inv.json?.ListSupplier && inv.json.ListSupplier.length > 0 ? inv.json.ListSupplier[0].Name : "", "displayName": "supplier_name" },
        //{ "name": "json.InvoiceNo", "evaluate": inv => inv.json ? inv.json.InvoiceNo : "", "displayName": "invoice_number" },
        //{ "name": "currency", "evaluate": inv => inv.currency ? inv.currency : "", "displayName": "currency" },
        //{ "name": "status", "evaluate": inv => inv.status, "displayName": "status" },
        //{ "name": "total", "evaluate": inv => inv.Total ? `$${inv.Total.toFixed(2)}` : "", "displayName": "total" },
        //{ "name": "totalWithTax", "evaluate": inv => inv.TotalWithTax ? `$${inv.TotalWithTax.toFixed(2)}` : "", "displayName": "total_with_tax" },
        //{ "name": "json.GLUnit", "evaluate": inv => inv.json?.GLUnit ? inv.json.GLUnit : "", "displayName": "unit" },
        //{ "name": "username", "evaluate": inv => inv.username ? inv.username : "", "displayName": "username" },
        //{ "name": "approver", "evaluate": inv => inv.approver ? `${inv.json.approver.first_name} ${inv.json.approver.last_name}` : "", "displayName": "approver" },
        //{ "name": "preview", "evaluate": inv => inv._id, "displayName": "preview"},
        //{ "name": "status_details", "evaluate": inv => inv.status_details, "displayName": "comments" }
    ]

    const search = (filter) => {
        const result = [...filters.filter(f => filter.id !== f.id), filter];
        setFilters(result);
    }

    const isAllGlNaturalsSelected = () => {
        if (!glNaturals || !glNaturals.length)
            return false;
        return glNaturalsDeleteSelection.length > 1 && glNaturalsDeleteSelection.length === glNaturals.length;
    }

    const deleteGlNaturals = () => {
        setSaving(true);
        ApiWrapper.fetch(null, `/gl_naturals/`, "DELETE", { "gl_natural_ids": glNaturalsDeleteSelection },
            response => {
                fetchGlNaturals();
                setGlNaturalsDeleteSelection([]);
                setGlNaturalsDeleteConfirm(false);
                setSaving(false);
            }
            , () => { setSaving(false); setGlNaturalsDeleteConfirm(false); }, false, source.token);
    }

    const fetchGlNaturals = () => {
        let start = page * pageSize;

        if (globalfilter.length !== 0 || filters.length !== 0)
            start = 0;

        const searchData = `?start=${encodeURIComponent(start)}&page_size=${encodeURIComponent(pageSize)}&globalfilter=${encodeURIComponent(globalfilter ?? '')}&filters=${encodeURIComponent(JSON.stringify(filters ?? []))}&sorting=${encodeURIComponent(JSON.stringify(sorting ?? []))}`;

        ApiWrapper.fetch(null, `/gl_naturals/${searchData}`, "GET", null, (response) => {
            setGlNaturals(response.data.gl_naturals);
            setLoading(false);
            setTotalRowCount(response.data.meta.totalRowCount);
        },
            (error) => {
                if (error.code === "ERR_NETWORK")
                    fetchGlNaturals()
            }
            , false, source.token);
    }

    useEffect(() => {
        fetchGlNaturals();
    }
        , [])

    useEffect(() => {
        return () => {
            source.cancel('gl_naturals list unmounted');
        }
    }, [])

    useEffect(() => {
        fetchGlNaturals();
    }, [page, sorting, filters])

    const sortTable = (name) => {
        if (saving) {
            return;
        }
        const sorter = sorting.find(s => s.id === name)

        if (sorter === undefined)
            return setSorting([{ "id": name, "desc": true }])

        if (sorter.desc)
            return setSorting([{ "id": name, "desc": false }])
        else
            return setSorting([])
    }

    return !loading ?
        savingError != null ?
            <Modal title={data.t.read("error")} okAction={() => setSavingError(null)} okText={"ok"}>
                <div className="text-danger">
                    {savingError}
                </div>
            </Modal>
            :
            <div className="card p-1 mt-5 rounded-lg">
                <div className="card-body">
                    <div className="card-title d-flex justify-content-center">
                        <h2>GL Codes</h2>
                    </div>
                    <div className="d-flex justify-content-end m-1">
                        <input key={"header-gl_natural-search"} type="text" className="form-control search-field mr-5 mt-1" placeholder={data.t.read("gl_natural")} onChange={e => setSearchStr(e.target.value)} onKeyUp={() => search({ "id": "GLNatural", "value": searchStr })} />
                        <button onClick={() => setShowImportModal(!showImportModal)} type="button" className="btn mr-1 btn-primary btn-primary-shadow">
                            {data.t.read("import")}
                        </button>
                        <button onClick={() => setSelected({})} type="button" className="btn mr-1 btn-primary btn-primary-shadow">
                            {data.t.read("add_new")}
                        </button>
                        <button type="button" className="mr-1 text-center pointer btn btn-sm btn-secondary btn-primary-shadow pb-2 pt-2" disabled={saving || glNaturalsDeleteSelection.length === 0} onClick={() => setGlNaturalsDeleteConfirm(true)}>
                            {data.t.read("delete")}
                            {saving && <FontAwesomeIcon className="ml-2 text-secondary infinite-rotate faded" icon={faSync} />}
                        </button>
                    </div>
                    <div className="justify-content-center min-height-70 align-items-center">
                        <table className="table table-hover col-10 m-auto  table-striped">
                            <thead>
                                <tr>
                                    <th><Checkbox disabled={!glNaturals || !glNaturals.length} isChecked={isAllGlNaturalsSelected()} onChange={toggleAllGlNaturalsSelected} /></th>
                                    <th>Actions</th>
                                    {availableColumns.map((availableColumn) => (
                                        <th style={{ width: "16%" }} className='pointer' onClick={() => sortTable(availableColumn.name)} key={`header-${availableColumn.displayName}}`}>
                                            {data.t.read(`${availableColumn.displayName}`)}
                                            {sorting.find(s => s.id === availableColumn.name) && <FontAwesomeIcon className={loading ? "infinite-rotate faded ml-2 fa-xs" : "ml-2 fa-xs"} icon={sorting.find(s => s.id === availableColumn.name).desc ? faArrowDown : faArrowUp} />}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {glNaturals.map(inv => <tr key={`${inv._id.$oid}-list`}>
                                    <td><Checkbox isChecked={isGlNaturalSelected(inv)} onChange={e => isGlNaturalSelected(inv) ? setGlNaturalsDeleteSelection(glNaturalsDeleteSelection.filter(x => x != inv._id.$oid)) : setGlNaturalsDeleteSelection([...glNaturalsDeleteSelection, inv._id.$oid])} /></td>
                                    <td>
                                        {<FontAwesomeIcon onClick={() => setSelected(inv)} className="mr-2" icon={faPencilAlt} />}
                                        {<FontAwesomeIcon onClick={() => { setGlNaturalsDeleteSelection([inv._id.$oid]); setGlNaturalsDeleteConfirm(true); }} icon={faWindowClose} style={{ color: 'red' }} />}
                                    </td>
                                    {availableColumns.map(availableColumn => <td onClick={() => setSelected(inv)} key={`${inv._id.$oid}-${availableColumn.displayName}-value`}>{availableColumn.evaluate(inv)}</td>)}
                                </tr>
                                )}
                            </tbody>
                        </table>
                        <div className='d-flex justify-content-center p-2'>
                            <div className="pagination">
                                <div className="mr-5">
                                    <span className='font-italic'>
                                        {page * pageSize + 1}-{page * pageSize + pageSize <= totalRowCount ? page * pageSize + pageSize : totalRowCount} {data.t.read("rule.of")}
                                    </span>
                                    <span className='p-1 text-primary'>
                                        {totalRowCount}
                                    </span>
                                </div>
                                <div className="ml-5">
                                    <button className='btn  btn-sm' disabled={loading || page === 0}>
                                        <FontAwesomeIcon key={"left-arrow-double"} className='fa-xs' onClick={() => setPage(0)} icon={faAngleDoubleLeft} />
                                    </button>
                                    <button className='btn  btn-sm' disabled={loading || page === 0}>
                                        <FontAwesomeIcon key={"left-arrow"} className='fa-xs' onClick={() => setPage(page - 1)} icon={faChevronLeft} />
                                    </button>
                                    <span key={`page-${page}`} className={'active text-primary'}>
                                        <span key={`page-n-${page}`} className='p-2'>{page + 1}</span>
                                    </span>
                                    <button className='btn btn-sm' disabled={loading || page + 1 === Math.ceil(totalRowCount / pageSize)}>
                                        <FontAwesomeIcon key={"right-arrow"} className='fa-xs' onClick={() => setPage(page + 1)} icon={faChevronRight} />
                                    </button>
                                    <button className='btn btn-sm' disabled={loading || page + 1 === Math.ceil(totalRowCount / pageSize)}>
                                        <FontAwesomeIcon key={"right-arrow-double"} className='fa-xs' onClick={() => setPage(Math.ceil(totalRowCount / pageSize) - 1)} icon={faAngleDoubleRight} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    glNaturalsDeleteConfirm == true ?
                        <Modal title={data.t.read("confirm")} cancelAction={() => setGlNaturalsDeleteConfirm(false)} okAction={() => deleteGlNaturals()} okText={data.t.read("delete")} cancelText={data.t.read("cancel")}>
                            {data.t.read("delete")} ?
                        </Modal>
                        :
                        null
                }
                {
                    selected !== null ?
                        <div className="overlay" onClick={() => setSelected(null)}>
                            <div onClick={e => { e.preventDefault(); e.stopPropagation() }}>
                                <GlNatural config={data.config} glNatural={selected} t={data.t} close={() => {setSelected(null) ; fetchGlNaturals();}} />
                            </div>
                        </div>
                        :
                        null
                }
                {
                    showImportModal &&
                    <Modal width="50vw" title="Importation" cancelAction={() => setShowImportModal(null)} okAction={() => processImport()} okText={data.t.read("import")} cancelText={data.t.read("cancel")}>
                        <div>
                            Format du fichier CSV :
                            <div className='card p-2'>
                                code gl; budget; categorie; label; active; <br />
                                code gl; budget; categorie; label; active; <br />
                                code gl; budget; categorie; label; active; <br />
                            </div>
                            <div className='mt-2'>
                                <input className='form-control' type="file" id="import-csv-file" onChange={f => f.target.files && f.target.files.length > 0 && setImportFile(f.target.files[0])} />
                            </div>
                        </div>
                    </Modal>
                }
            </div>
        :
        <div className="d-flex loading-container">
            <FontAwesomeIcon className="infinite-rotate" icon={faSpinner} />
        </div>
}

export default inject('data')(withRouter(observer(AdminGlNaturals)));