import React, { Component } from 'react';
import { observer, inject  } from "mobx-react";
import {Link, withRouter} from "react-router-dom";
import ApiWrapper from '../../utils/api-wrapper';

export default inject("data")(withRouter(observer(class AdminDashboard extends Component  {
    resetLocalDB(){
        ApiWrapper.fetch(this, "/reset-local-db", "GET", {});
    }

    render() {
        return  <div>
                    <div className='d-flex'>
                        <div className='mr-2'>
                            <Link to="/admin/clients" className='btn btn-lg btn-primary'>
                                Clients
                            </Link>
                        </div>
                        <div>
                            <Link to="/admin/suppliers" className='btn btn-lg btn-primary'>
                                Fournisseurs
                            </Link>
                        </div>
                        <div>
                            <Link to="/admin/gl_naturals" className='ml-2 btn btn-lg btn-primary'>
                                GL Codes
                            </Link>
                        </div>
                    </div>
                        <div className="mt-5">
                            <button className='btn btn-lg btn-primary' onClick={() => this.resetLocalDB()}>
                                Reset local DB
                            </button>
                        </div>
                </div>
    }
})));