import React, { useEffect, useContext } from 'react';
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { inject, observer, Provider, MobXProviderContext } from "mobx-react";
import ApiWrapper from './utils/api-wrapper';
import Utils from './utils/utils';

import SignIn from './pages/signin';
import Settings from './pages/settings';
import Dashboard from './pages/dashboard';
import NewInvoice from './pages/new-invoice';
import Monitor from './pages/monitor';
import Cashflow from './pages/cashflow';
import Data from './pages/data';
import Workflow from './pages/workflow';
import AdminDashboard from './pages/admin/admin-dashboard';
import AdminClients from "./pages/admin/admin-clients";
import AdminSuppliers from "./pages/admin/admin-suppliers";
import AdminGlNaturals from "./pages/admin/admin-gl-naturals";
import Archives from './pages/archives';

import Header from './components/header';
import Footer from './components/footer';
import Menu from './components/menu';

import 'popper.js';
import 'bootstrap';
import Reports from './pages/reports';
import Paiements from './pages/paiements';
import PaiementsApproval from './pages/paiements-approval';
import PlProcessingAccount from './pages/pl-processing-account';
import ExpensesAccount from './pages/expenses_account';
import ExpensesAccountCreation from './pages/expenses_account_creation';
import NewExpenses from './pages/new-expenses';

const App = inject("data")(observer(({ data }) => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        ApiWrapper.fetch(null, "/refresh-token", "GET", {},
            response => {
                data.username = response.data.username;
                data.firstName = response.data.first_name;
                data.lastName = response.data.last_name;
                data.unit = response.data.unit;
                data.role = response.data.role;
                data.type = response.data.type;
                data.config = response.data.config;
                data.client_name = response.data.client_name;

                window.localStorage.setItem("jwt", response.data.token);

                if (location.pathname === "/signin" || location.pathname === "/") {
                    history.replace('/dashboard');
                }
            },
            response => {
                window.localStorage.removeItem("jwt");
                history.replace('/signin');
            }
        );
    }, [history, location.pathname, data]);

    return (
        <div className="App">
            <Provider data={data}>
                <Main />
            </Provider>
        </div>
    );
}));

const Main = inject("data")(observer(({ data }) => {
    return (
        <div className="full-height">
            <Header />
            {
                data.username !== null && data.username !== "" && !data.hideMenu ?
                    <Menu />
                    :
                    null
            }
            {(!data.isMobile || data.isMobile && !data.isMenuOpen) && <div className={data.username !== null && data.username !== "" && !data.hideMenu && !data.isMobile ? "offset-menu p-4" : ""}>
                <Switch>
                    <PropsRoute path="/dashboard" component={Dashboard} />
                    <PropsRoute path="/reports" component={Reports} />
                    <PropsRoute path="/archives" component={Archives} />
                    <PropsRoute path="/payments/invoices" component={Paiements} />
                    <PropsRoute path="/payments/approval" component={PaiementsApproval} />
                    <PropsRoute path="/payments/processing_account" component={PlProcessingAccount} />
                    <PropsRoute path="/expenses_accounts" exact component={ExpensesAccount} />
                    <PropsRoute path="/expenses_account/:account_id" exact component={ExpensesAccountCreation} />
                    <PropsRoute path="/expenses_account/:account_id/add" exact component={NewExpenses} />

                    {Utils.userHasPageAccess(data, "monitor") ? <PropsRoute path="/monitor" component={Monitor} /> : null}
                    {Utils.userHasPageAccess(data, "cashflow") ? <PropsRoute path="/cashflow" component={Cashflow} /> : null}
                    {Utils.userHasPageAccess(data, "data") ? <PropsRoute path="/data" component={Data} /> : null}
                    {Utils.userHasPageAccess(data, "workflow") ? <PropsRoute path="/workflow" component={Workflow} /> : null}
                    {Utils.userHasPageAccess(data, "admin") ? <PropsRoute path="/admin" exact component={AdminDashboard} /> : null}
                    {Utils.userHasPageAccess(data, "admin") ? <PropsRoute path="/admin/clients" exact component={AdminClients} /> : null}
                    {Utils.userHasPageAccess(data, "admin") ? <PropsRoute path="/admin/suppliers" exact component={AdminSuppliers} /> : null}
                    {Utils.userHasPageAccess(data, "admin") ? <PropsRoute path="/admin/gl_naturals" exact component={AdminGlNaturals} /> : null}
                    <PropsRoute path="/signin" component={SignIn} />
                    <PropsRoute path="/settings" component={Settings} />
                    <PropsRoute path="/new" component={NewInvoice} />
                </Switch>
            </div>}
            <Footer />
        </div>
    );
}));

const renderMergedProps = (component, ...rest) => {
    const finalProps = Object.assign({}, ...rest);
    return React.createElement(component, finalProps);
}

const PropsRoute = ({ component, ...rest }) => {
    return (
        <Route {...rest} render={routeProps => {
            return renderMergedProps(component, routeProps, rest);
        }} />
    );
}

export default App;