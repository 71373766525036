import React, { Component } from 'react';
import { observer, inject  } from "mobx-react";
import ApiWrapper from '../utils/api-wrapper';
import logo from './../assets/img/logo-xpens-cropped.svg';
import Translator from '../locales/translator';
import Utils from '../utils/utils';

export default inject("data")(observer(class Signin extends Component  {

    constructor(props) {
        super(props);

        this.state = {
            username : "",
            password : "",
            errorMessage : null,
        }
    }

    updateLang() {
        this.props.data.t.switchLang();
        this.props.data.t = new Translator();
    }

    handleSubmit(event) {
		event.preventDefault();

        let data = new FormData();
        data.append("username", this.state.username.trim());
        data.append("password", this.state.password);

        ApiWrapper.fetch(this, "/auth", "POST", data,
        response => {
            window.localStorage.setItem("jwt", response.data.token);
            this.props.data.firstName = response.data.first_name;
            this.props.data.lastName = response.data.last_name;
            this.props.data.username = this.state.username.trim();
			this.props.data.unit = response.data.unit;
            this.props.data.role = response.data.role;
            this.props.data.config = response.data.config;
            this.props.data.client_name = response.data.client_name;
            
            if (!this.props.data.isMobile)
                this.props.history.replace('/dashboard') 
            else if (Utils.userHasPageAccess(this.props.data, "expenses"))
                this.props.history.replace('/expenses_accounts'); 
            else 
                {this.props.data.username = null;
                this.setState({errorMessage : this.props.data.t.read("signin.invalid_credentials_provided")})}

        },
        response => {
            this.setState({
                errorMessage : this.props.data.t.read("signin.invalid_credentials_provided")
            })
        })
	}

    render() {
        return  <div>
                    <div className="pointer position-absolute" style={{right : 20, top : 20}} onClick={() => this.updateLang() }>
                        {
                            this.props.data.t.lang === "fr" ?
                                <span><strong>Français</strong> / English</span>
                            :
                                <span>Français / <strong>English</strong></span>
                        }
                    </div>
                    <div className="page-centered">
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="text-center mb-5">
                                <img src={logo} alt="Head Logo" />
                            </div>
                            <div className="alert-section">
                                {
                                    this.state.errorMessage != null ?
                                        <div className="alert alert-danger" role="alert">
                                            {this.state.errorMessage}
                                        </div>
                                    :
                                        null
                                }
                            </div>
                            <form className="" method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
                                <div className="form-group">
                                    <input className="form-control" placeholder={this.props.data.t.read("username")} required type="text" name="username" onChange={(event) => this.setState({username : event.target.value})} />
                                </div>
                                <div className="form-group">
                                    <input className="form-control" placeholder={this.props.data.t.read("password")} required type="password" name="password" onChange={(event) => this.setState({password : event.target.value})} />
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-block btn-primary">{this.props.data.t.read("signin.signin")}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
    }
}));